<template>
  <div>
    <guest-header v-if="!loggedIn" />
    <admin-header v-else />
  </div>
</template>

<script>
  import "element-ui/lib/theme-chalk/display.css";
  import { mapGetters } from "vuex";
  // import { computed } from "vue";
  import { labels } from "@/common";
  import GuestHeader from "@/components/GuestHeader.vue";
  import AdminHeader from "@/components/AdminHeader.vue";

  export default {
    name: "AppHeader",

    components: { GuestHeader, AdminHeader },

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapGetters({ loggedIn: "auth/loggedIn" }),
    },

    // setup() {
    //   const store = useStore();

    //   let loggedIn = computed(() => store.getters["auth/loggedIn"]);

    //   return {
    //     labels,
    //     loggedIn,
    //   };
    // },
  };
</script>
