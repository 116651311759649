import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";

import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  editableContents: [],
  editableContentDetail: null,
};

const mutations = {
  SET_CONTENTS: (state, editableContents) => {
    state.editableContents = editableContents;
  },

  SET_CONTENT_DETAIL: (state, detail) => {
    state.editableContentDetail = detail;
  },
};

const actions = {
  async getEditableContentDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`/editable-contents/${id}`);
      if (status === 200) {
        commit("SET_CONTENT_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllEditableContents({ commit }, params = {}) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/editable-contents", {
        params,
      });
      if (status === 200) {
        commit("SET_CONTENTS", data);
        return data;
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createEditableContent({ commit }, service) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        "/editable-contents",
        createFormData(service)
      );
      if (status === 200) {
        router.push({ name: "CMS Company" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Editable Content successfully created",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateEditableContent({ commit }, updatedEditableContent) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        `/editable-contents/${updatedEditableContent.id}`,
        createFormData(updatedEditableContent)
      );
      if (status === 200) {
        router.push({ name: "CMS Company" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Editable Content successfully updated",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteEditableContent({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/editable-contents/${id}`);
      if (status === 200) {
        dispatch("getAllEditableContents", { limit: 100 });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Editable Content successfully deleted",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
