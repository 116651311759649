import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";

import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  projects: [],
  projectDetail: null,
};

const mutations = {
  SET_PROJECTS: (state, projects) => {
    state.projects = projects;
  },

  SET_PROJECT_DETAIL: (state, detail) => {
    state.projectDetail = detail;
  },
};

const actions = {
  async getProjectDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`projects/${id}`);
      if (status === 200) {
        commit("SET_PROJECT_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllProjects({ commit }, params = {}) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/projects", { params });
      if (status === 200) {
        commit("SET_PROJECTS", data);
        return data;
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createProject({ commit }, project) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post("/projects", createFormData(project));
      if (status === 201) {
        router.push({ name: "CMS Projects" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Project successfully created",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateProject({ commit }, updatedProject) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        `/projects/${updatedProject.id}`,
        createFormData(updatedProject)
      );
      if (status === 200) {
        router.push({ name: "CMS Projects" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Project successfully updated",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteProject({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/projects/${id}`);
      if (status === 200) {
        dispatch("getAllProjects", { limit: 100 });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Project successfully deleted",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
