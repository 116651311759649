import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";

import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  clients: null,
  clientDetail: null,
};

const mutations = {
  SET_CLIENTS: (state, clients) => {
    state.clients = clients;
  },

  SET_CLIENT_DETAIL: (state, detail) => {
    state.clientDetail = detail;
  },
};

const actions = {
  async getClientDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`clients/${id}`);
      if (status === 200) {
        commit("SET_CLIENT_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        router.push({
          name: "Not Found",
          params: { resource: "client" },
        });
      } else router.push({ name: "Network Error" });
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllClients({ commit }, params = { page: 1 }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/clients", {
        params,
      });

      if (status === 200) {
        commit("SET_CLIENTS", data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createClient({ commit }, company) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post("/clients", createFormData(company));
      if (status === 201) {
        router.push({ name: "CMS Clients" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_CLIENT_CREATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateClient({ commit }, updatedClient) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        `/clients/${updatedClient.id}`,
        createFormData(updatedClient)
      );
      if (status === 200) {
        router.push({ name: "CMS Clients" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_CLIENT_UPDATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteClient({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/clients/${id}`);
      if (status === 200) {
        dispatch("getAllClients");
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_CLIENT_DELETED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
