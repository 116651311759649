import labels from "./labels";

const routes = [
  { title: labels.MENU_HOME, path: "/" },
  { title: labels.MENU_ABOUT, path: "/about-us" },
  { title: labels.MENU_PRODUCTS, path: "/products" },
  { title: labels.MENU_SERVICES, path: "/services" },
  { title: labels.MENU_PROJECTS, path: "/projects" },
  { title: labels.MENU_CLIENTS, path: "/clients" },
  { title: labels.MENU_GALLERY, path: "/gallery" },
  // { title: labels.MENU_TEAMS, path: "/team" },
  { title: labels.MENU_NEWS, path: "/news" },
  { title: labels.MENU_CONTACT, path: "/contact-us" },
];

export default routes;
