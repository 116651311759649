<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    :aria-labelledby="title"
    role="presentation"
  >
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: "box",
      },
      size: {
        type: [Number, String],
        default: 18,
      },
      iconColor: {
        type: String,
        default: "currentColor",
      },
    },
  };
</script>

<style scoped>
  svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -2px; /* yes, I'm that particular about formatting */
  }
</style>
