import axios from "axios";
import { labels } from "@/common";
import { Notification, Loading } from "element-ui";

const state = {
  subscribers: null,
};

const mutations = {
  SET_SUBSCRIBERS: (state, subscribers) => {
    state.subscribers = subscribers;
  },
};

const actions = {
  async subscribe(_, subscriber) {
    const loadingInstance = Loading.service({
      lock: true,
      text: labels.LBL_SUBSRCRIBING,
      background: "rgba(0, 0, 0, 0.7)",
    });
    try {
      const { status } = await axios.post("/subscribers", subscriber);
      if (status === 200) {
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_SUBSCRIBE_SUCCESS,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.errors.email[0],
        });
      }
    } finally {
      loadingInstance.close();
    }
  },

  async getAllSubscribers({ commit }, params = { page: 1 }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const {
        status,
        data,
        data: { current_page, last_page, per_page, total },
      } = await axios.get("/subscribers", {
        params,
      });
      const paginationData = { current_page, last_page, per_page, total };
      if (status === 200) {
        commit("SET_SUBSCRIBERS", { ...data, meta: paginationData });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteSubscriber({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/subscribers/${id}`);
      if (status === 200) {
        dispatch("getAllSubscribers");
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_SUBSCRIBER_DELETED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
