import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";
import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  products: [],
  productDetail: null,
};

const mutations = {
  SET_PRODUCTS: (state, products) => {
    state.products = products;
  },

  SET_PRODUCT_DETAIL: (state, detail) => {
    state.productDetail = detail;
  },
};

const actions = {
  async getProductDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`products/${id}`);
      if (status === 200) {
        commit("SET_PRODUCT_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllProducts({ commit }, params = {}) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/products", { params });
      if (status === 200) {
        commit("SET_PRODUCTS", data);
        return data;
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createProduct({ commit }, product) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post("/products", createFormData(product));
      if (status === 201) {
        router.push({ name: "CMS Products" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Product successfully created",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateProduct({ commit }, updatedProduct) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        `/products/${updatedProduct.id}`,
        createFormData(updatedProduct)
      );
      if (status === 200) {
        router.push({ name: "CMS Products" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Product successfully updated",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteProduct({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/products/${id}`);
      if (status === 200) {
        dispatch("getAllProducts", { limit: 100 });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Product successfully deleted",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
