import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";
import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  designations: [],
  executives: null,
  executiveDetail: null,
  salutations: [],
};

const mutations = {
  SET_SALUTATIONS: (state, salutations) => {
    state.salutations = salutations;
  },

  SET_DESIGNATIONS: (state, designations) => {
    state.designations = designations;
  },

  SET_FISCALYEARS: (state, fiscalYears) => {
    state.fiscalYears = fiscalYears;
  },

  SET_EXECUTIVES: (state, executives) => {
    state.executives = executives;
  },

  SET_EXECUTIVE_DETAIL: (state, detail) => {
    state.executiveDetail = detail;
  },
};

const actions = {
  async getSalutations({ commit }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/common-code/SLT");
      if (status === 200) {
        commit("SET_SALUTATIONS", data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getDesignations({ commit }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/designations");
      if (status === 200) {
        commit("SET_DESIGNATIONS", data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllExecutives({ commit }, params = {}) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/executives", { params });
      if (status === 200) {
        commit("SET_EXECUTIVES", data);
        return data;
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createExecutive({ commit }, executive) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        "/executives",
        createFormData(executive)
      );
      if (status === 201) {
        router.push({ name: "CMS Team" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_MEMBER_SUCCESS,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getExecutiveDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`executives/${id}`);
      if (status === 200) {
        commit("SET_EXECUTIVE_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateExecutive({ commit }, updatedExecutive) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        `/executives/${updatedExecutive.id}`,
        createFormData(updatedExecutive)
      );
      if (status === 200) {
        router.push({ name: "CMS Team" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_MEMBER_UPDATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async toggleAccess({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.get(`/executives/mail/${id}`);
      if (status === 200) {
        router.push({ name: "CMS Team" });
        dispatch("getAllExecutives");
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_LOGIN_STATUS_UPDATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteExecutive({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/executives/${id}`);
      if (status === 200) {
        dispatch("getAllExecutives", { limit: 100 });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_MEMBER_DELETED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {
  executivesDDL: (state) =>
    state.executives &&
    state.executives.data.map((e) => ({
      id: e.id,
      name: `${e.first_name} ${e.last_name}`,
    })),

  salutationDDL: (state) =>
    state.salutations.commoncodes &&
    state.salutations.commoncodes.map((s) => ({
      id: s.id,
      label: s.common_code,
    })),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
