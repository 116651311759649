<template>
  <div>
    <div class="contact-info__wrapper hidden-sm-and-down">
      <div class="container">
        <div class="contact-info">
          <div class="contact-info__item">
            <icon-base class="icon">
              <icon-mail />
            </icon-base>

            <a :href="`mailto:${email}`">
              {{ email }}
            </a>
          </div>

          <div class="contact-info__item">
            <icon-base class="icon">
              <icon-phone />
            </icon-base>

            <a :href="`tel:${phoneNo}`">
              <span>
                {{ phoneNo }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <header :class="headerBackground">
      <div class="container">
        <div class="navigation">
          <div class="logo">
            <router-link to="/">
              <!-- <img
              v-if="!userHasScrolled"
              :src="require('@/assets/images/defaults/logo.png')"
              alt="Logo"
              style="height: 40px"
            />
            <img
              v-else
              :src="require('@/assets/images/defaults/logo-full.png')"
              alt="Logo"
              style="height: 80px"
            /> -->
              <app-logo />
            </router-link>

            <div class="nav-menu hidden-sm-and-up" @click="toggleMenu">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </div>
          </div>

          <div class="navigation__links hidden-sm-and-down">
            <router-link
              v-for="nav in navlinks"
              :key="nav.title"
              :to="nav.path"
              class="navlink"
              :exact-active-class="nav.path && 'active-link'"
            >
              {{ nav.title }}
            </router-link>

            <!-- <img
            :src="require('@/assets/images/defaults/certification.png')"
            alt="certication"
            class="certification"
          />

          <a :href="`tel:${phoneNo}`" v-if="phoneNo" class="phone-number">
            <i class="el-icon-phone-outline mr-2"></i>
            {{ phoneNo }}
          </a> -->
          </div>

          <!-- Navigation for small screen  -->
          <the-side-nav v-model="showMenu" @closeSidebar="showMenu = false">
            <router-link
              v-for="nav in navlinks"
              :key="nav.title"
              :to="nav.path"
            >
              {{ nav.title }}
            </router-link>
          </the-side-nav>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
  import { labels, gRoutes } from "@/common";

  import AppLogo from "./AppLogo.vue";
  import TheSideNav from "@/components/TheSideNav";
  import IconBase from "./IconBase.vue";
  import IconPhone from "./icons/IconPhone.vue";
  import IconMail from "./icons/IconMail.vue";

  export default {
    name: "GuestHeader",

    components: {
      TheSideNav,
      IconBase,
      IconPhone,
      IconMail,
      AppLogo,
    },

    data() {
      return {
        labels,
        navlinks: [...gRoutes],

        company: null,

        scrollPosition: null,
        showMenu: false,
      };
    },

    computed: {
      phoneNo() {
        return this.company && this.company.phone;
      },

      email() {
        return this.company && this.company.email;
      },

      userHasScrolled() {
        return this.scrollPosition > 100;
      },

      headerBackground() {
        return this.userHasScrolled ? "onScroll" : "default";
      },

      isHomePage() {
        return this.$route.name === "Home";
      },
    },

    created() {
      this.loadOrgDetails();
    },

    mounted() {
      window.addEventListener("scroll", this.updateOnScroll);
    },

    destroyed() {
      window.removeEventListener("scroll", this.updateOnScroll);
    },

    methods: {
      async loadOrgDetails() {
        this.company = await this.$store.dispatch(
          "organization/getOrganizationDetail"
        );
      },

      updateOnScroll() {
        this.scrollPosition = window.scrollY;
      },

      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .wrapper {
    //background: linear-gradient(to right, white 50%, $primary-color);
    background-color: $primary-color;
  }

  .contact-info__wrapper {
    // background: linear-gradient(to right, white 30%, $primary-color);
    background: $primary-color;
  }

  .contact-info {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0.15rem;
    margin-right: 4rem;

    &__item {
      display: flex;
      align-items: center;
      color: white;

      .icon {
        // color: black;
        margin-right: 0.5rem;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  .certification {
    height: 60px;
    width: 150px;
  }

  header {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;

    padding: 0 8rem;
    flex-shrink: 0;

    a {
      text-decoration: none;
      color: inherit;
    }

    transition: all 200ms ease-out;

    @media only screen and (min-device-width: 1500px) {
      padding: 0 12rem;
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: $header-height;

    &__links {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;

      & > .navlink {
        margin: 0 0.75rem;
        padding: 1.5rem 0;
        font-size: 0.9rem;
        font-weight: 400;
        letter-spacing: 1px;
      }
    }
  }

  .active-link {
    color: $primary-color;
  }

  .nav-menu {
    cursor: pointer;
  }

  .default {
    background: transparent;
  }

  .onScroll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.295);

    .active-link {
      border-bottom: 5px solid $primary-color;
    }
  }

  .phone-number {
    display: flex;
    align-items: center;
    margin-left: 3rem;
    color: $primary-color;
    font-weight: 500;

    i {
      font-size: 1.75rem;
      animation: ring 2s 2s infinite;
    }
  }

  @media only screen and (max-device-width: 768px) {
    header {
      padding: 1rem;
    }

    .logo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .navigation {
      height: 40px;
    }
  }
</style>
