<template>
  <div>
    <subscribe-newsletter />

    <div class="footer">
      <div class="container default-padding">
        <div class="content-wrapper">
          <el-row :gutter="20">
            <el-col :span="24" :lg="8" class="mb-4" data-aos="fade-up">
              <app-logo />

              <div class="my-4" style="padding-right: 2rem">
                Allied Power Engineering (APE) is ISO 9001:2015 certified firm which is
                based in Hetauda Industrial District (HID) of
                Hetauda city, Ward no. 8, Makawanpur District of Nepal.
              </div>

              <router-link to="/privacy-policy" class="footer-submenu">
                Privacy Policy
              </router-link>
              <router-link to="/terms-and-conditions" class="footer-submenu">
                Terms and Conditions
              </router-link>
            </el-col>

            <el-col
              :span="24"
              :lg="5"
              class="mb-4"
              data-aos="fade-up"
              data-aos-offset="150"
            >
              <div class="mb-2 footer-menu">
                Explore
              </div>

              <router-link
                v-for="route in gRoutes"
                :key="route.title"
                :to="route.path"
                class="footer-submenu"
              >
                {{ route.title }}
              </router-link>
            </el-col>

            <el-col
              :span="24"
              :lg="5"
              class="mb-4"
              data-aos="fade-up"
              data-aos-offset="200"
            >
              <div class="mb-2 footer-menu">
                {{ labels.MENU_PRODUCTS }}
              </div>

              <router-link
                v-for="{ id, name } in products"
                :key="id"
                :to="{ name: 'Products', hash: `#${id}` }"
                class="footer-submenu"
              >
                {{ name }}
              </router-link>
            </el-col>

            <el-col
              :span="24"
              :lg="6"
              class="mb-4"
              data-aos="fade-up"
              data-aos-offset="250"
            >
              <div v-if="phoneNo || email" class="mb-2 footer-menu">
                {{ labels.MENU_CONTACT }}
              </div>

              <div v-if="phoneNo" class="footer-submenu">
                <icon-base class="mr-1">
                  <icon-phone-filled />
                </icon-base>

                <a :href="`tel:${phoneNo}`">
                  {{ phoneNo }}
                </a>
              </div>

              <div v-if="email" class="footer-submenu">
                <icon-base class="mr-1">
                  <icon-mail-filled />
                </icon-base>

                <a :href="`mailto:${email}`">
                  {{ email }}
                </a>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <hr />

      <div align="center" class="container py-4">
        <div class="copy-rights">
          {{ currentYear }} {{ labels.LBL_COPY_RIGHT }}
          {{ labels.LBL_ALL_RIGHTS }}
        </div>

        <div class="design-develop">
          {{ labels.LBL_DESIGN_DEVELOP }}
          <a :href="labels.DEV_WEBSITE" target="_blank">
            {{ labels.DEV_NAME }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { labels, gRoutes } from "@/common";
  import IconBase from "@/components/IconBase";
  import IconPhoneFilled from "@/components/icons/IconPhoneFilled";
  import IconMailFilled from "@/components/icons/IconMailFilled";
  import AppLogo from "../AppLogo.vue";
  import SubscribeNewsletter from "../SubscribeNewsletter.vue";
  import { mapState } from "vuex";

  export default {
    name: "AppFooter",

    components: {
      AppLogo,
      IconBase,
      IconPhoneFilled,
      IconMailFilled,
      SubscribeNewsletter,
    },

    data() {
      return {
        labels,
        gRoutes,

        company: null,
      };
    },

    computed: {
      ...mapState({
        products: (state) => state.product.products.data,
      }),

      email() {
        return this.company && this.company.email;
      },

      phoneNo() {
        return this.company && this.company.phone;
      },

      facebook() {
        return this.company && this.company.facebook_url;
      },

      twitter() {
        return this.company && this.company.twitter_url;
      },

      linkedIn() {
        return this.company && this.company.linkedin_url;
      },

      socialMedias() {
        return this.facebook || this.twitter || this.linkedIn;
      },

      currentYear() {
        return new Date().getFullYear();
      },
    },

    created() {
      this.loadOrgDetails();
    },

    methods: {
      async loadOrgDetails() {
        this.company = await this.$store.dispatch(
          "organization/getOrganizationDetail"
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .footer {
    // box-sizing: border-box;
    background: $tertiary-color;
    color: white;

    // min-height: 400px;
    width: 100%;
    padding-top: 100px;

    font-size: 0.9rem;

    .content-wrapper {
      .footer-menu {
        font-size: 1rem;
        display: inline-flex;
        border-bottom: 1px solid $primary-color;
        padding-bottom: 0.25rem;
      }

      .footer-submenu {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        color: lightgray;
        transition: all 200ms ease;

        svg {
          color: $primary-color;
          flex-shrink: 0;
        }

        &:hover {
          border-left: 1px solid white;
          padding-left: 1rem;
          color: lighten($color: $primary-color, $amount: 20);
        }
      }
    }
  }

  .social-medias {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;

    a {
      &:not(:first-child, :last-child) {
        margin: 0 0.75rem;
      }

      svg {
        height: 23px;
        width: 23px;
        flex-shrink: 0;
        margin-top: 4px;
      }
    }
  }

  .copy-rights,
  .design-develop {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  .design-develop {
    margin-top: 0.5rem;
    a {
      text-decoration: none;
      color: $primary-color;
    }
  }

  @media only screen and (max-device-width: 768px) {
    .content-wrapper {
      margin: 0 auto !important;
    }

    .footer {
      padding-top: 0;
    }
  }
</style>
