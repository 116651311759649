import moment from "moment";

const readableDate = (date) => moment(date).format("MMM Do, YYYY");

const kFormat = (num) =>
  Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
    : Math.sign(num) * Math.abs(num);

export { readableDate, kFormat };
