import Vue from "vue";
import VueRouter from "vue-router";

import GuestMiddleware from "./middleware/guest";
import AuthMiddleware from "./middleware/auth";
import GuestLayout from "@/components/layouts/GuestLayout";
import AdminLayout from "@/components/layouts/AdminLayout";

Vue.use(VueRouter);

const RouterView = {
  render(c) {
    return c("router-view");
  },
};

const routes = [
  {
    path: "/",
    beforeEnter: GuestMiddleware,
    component: GuestLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
      },
      {
        path: "about-us",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/AboutUs.vue"),
      },
      {
        path: "contact-us",
        name: "Contact Us",
        component: () =>
          import(/* webpackChunkName: "contact-us" */ "@/views/ContactUs.vue"),
      },
      // {
      //   path: "team",
      //   name: "Team",
      //   component: () =>
      //     import(/* webpackChunkName: "contact-us" */ "@/views/Team.vue"),
      // },
      {
        path: "news",
        component: RouterView,
        children: [
          {
            path: "",
            name: "News",
            component: () =>
              import(/* webpackChunkName: "news" */ "@/views/news/Index.vue"),
          },
          {
            path: ":newsId",
            name: "News Detail",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "newsDetail" */ "@/views/news/Show.vue"
              ),
          },
        ],
      },
      {
        path: "products",
        name: "Products",
        component: () =>
          import(/* webpackChunkName: "products" */ "@/views/Products.vue"),
      },
      {
        path: "services",
        name: "Services",
        component: () =>
          import(/* webpackChunkName: "services" */ "@/views/Services.vue"),
      },
      {
        path: "projects",
        name: "Projects",
        component: () =>
          import(/* webpackChunkName: "projects" */ "@/views/Projects.vue"),
      },
      {
        path: "gallery",
        component: RouterView,
        children: [
          {
            path: "",
            name: "Gallery",
            component: () =>
              import(
                /* webpackChunkName: "gallery" */ "@/views/gallery/Index.vue"
              ),
          },
          {
            path: ":projectId",
            name: "Project Detail",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "gallery" */ "@/views/gallery/Show.vue"
              ),
          },
        ],
      },
      {
        path: "clients",
        name: "Clients",
        component: () =>
          import(/* webpackChunkName: "services" */ "@/views/Clients.vue"),
      },
      {
        path: "privacy-policy",
        name: "Privacy Policy",
        component: () =>
          import(
            /* webpackChunkName: "privacy-policy" */ "@/views/PrivacyPolicy.vue"
          ),
      },
      {
        path: "terms-and-conditions",
        name: "Terms Conditions",
        component: () =>
          import(
            /* webpackChunkName: "terms-conditions" */ "@/views/Terms.vue"
          ),
      },
    ],
  },
  {
    path: "/admin",
    beforeEnter: AuthMiddleware,
    component: AdminLayout,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/app/Dashboard.vue"),
      },
      {
        path: "banners",
        component: RouterView,
        children: [
          {
            path: "",
            name: "Banners",
            component: () =>
              import(
                /* webpackChunkName: "banners" */ "@/views/app/banners/Index.vue"
              ),
          },
          {
            path: "add",
            name: "Add Banner",
            component: () =>
              import(
                /* webpackChunkName: "add-banner" */ "@/views/app/banners/Add.vue"
              ),
          },
          {
            path: "edit/:bannerId",
            name: "Edit Banner",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "add-banner" */ "@/views/app/banners/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "gallery",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS Gallery",
            component: () =>
              import(
                /* webpackChunkName: "gallery" */ "@/views/app/gallery/Index.vue"
              ),
          },
          {
            path: "add",
            name: "Add Album",
            component: () =>
              import(
                /* webpackChunkName: "add-album" */ "@/views/app/gallery/Add.vue"
              ),
          },
          {
            path: "edit/:albumId",
            name: "Edit Album",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "edit-album" */ "@/views/app/gallery/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "news",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS News",
            component: () =>
              import(
                /* webpackChunkName: "cms-news" */ "@/views/app/news/Index.vue"
              ),
          },
          {
            path: "add",
            name: "Add News",
            component: () =>
              import(
                /* webpackChunkName: "add-news" */ "@/views/app/news/Add.vue"
              ),
          },
          {
            path: "edit/:newsId",
            name: "Edit News",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "edit-news" */ "@/views/app/news/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "products",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS Products",
            component: () =>
              import(
                /* webpackChunkName: "products" */ "@/views/app/products/Index.vue"
              ),
          },
          {
            path: "add",
            name: "Add Product",
            component: () =>
              import(
                /* webpackChunkName: "add-product" */ "@/views/app/products/Add.vue"
              ),
          },
          {
            path: "edit/:productId",
            name: "CMS Product",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "edit-product" */ "@/views/app/products/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "services",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS Services",
            component: () =>
              import(
                /* webpackChunkName: "services" */ "@/views/app/services/Index.vue"
              ),
          },
          {
            path: "add",
            name: "Add Service",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "services" */ "@/views/app/services/Add.vue"
              ),
          },
          {
            path: "edit/:serviceId",
            name: "Edit Service",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "services" */ "@/views/app/services/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "projects",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS Projects",
            component: () =>
              import(
                /* webpackChunkName: "projects" */ "@/views/app/projects/Index.vue"
              ),
          },
          {
            path: "",
            name: "Add Project",
            component: () =>
              import(
                /* webpackChunkName: "add-project" */ "@/views/app/projects/Add.vue"
              ),
          },
          {
            path: "edit/:projectId",
            name: "Edit Project",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "edit-project" */ "@/views/app/projects/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "team",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS Team",
            component: () =>
              import(
                /* webpackChunkName: "team" */ "@/views/app/team/Index.vue"
              ),
          },
          {
            path: "add",
            name: "Add Member",
            component: () =>
              import(
                /* webpackChunkName: "add-member" */ "@/views/app/team/Add.vue"
              ),
          },
          {
            path: "edit/:memberId",
            name: "Edit Member",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "edit-member" */ "@/views/app/team/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "feedbacks",
        component: RouterView,
        children: [
          {
            path: "",
            name: "Feedbacks",
            component: () =>
              import(
                /* webpackChunkName: "feedbacks" */ "@/views/app/feedbacks/Index.vue"
              ),
          },
          {
            path: ":feedbackId",
            name: "View Feedback",
            component: () =>
              import(
                /* webpackChunkName: "feedback-detail" */ "@/views/app/feedbacks/Show.vue"
              ),
            props: true,
          },
          {
            path: "reply/:replyTo",
            name: "Reply Feedback",
            component: () =>
              import(
                /* webpackChunkName: "feedback-reply" */ "@/views/app/feedbacks/Reply.vue"
              ),
            props: true,
          },
        ],
      },
      {
        path: "subscribers",
        name: "Subscribers",
        component: () =>
          import(
            /* webpackChunkName: "subscribers" */ "@/views/app/Subscribers.vue"
          ),
      },
      {
        path: "clients",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS Clients",
            component: () =>
              import(
                /* webpackChunkName: "companies" */ "@/views/app/clients/Index.vue"
              ),
          },
          {
            path: "add",
            name: "Add Client",
            component: () =>
              import(
                /* webpackChunkName: "add-company" */ "@/views/app/clients/Add.vue"
              ),
          },
          {
            path: "edit/:clientId",
            name: "Edit Client",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "edit-company" */ "@/views/app/clients/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "testimonials",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS Testimonials",
            component: () =>
              import(
                /* webpackChunkName: "testimonials" */ "@/views/app/testimonials/Index.vue"
              ),
          },
          {
            path: "add",
            name: "Add Testimonial",
            component: () =>
              import(
                /* webpackChunkName: "add-testimonial" */ "@/views/app/testimonials/Add.vue"
              ),
          },
          {
            path: "edit/:testimonialId",
            name: "Edit Testimonial",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "edit-testimonial" */ "@/views/app/testimonials/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "company-detail",
        component: RouterView,
        children: [
          {
            path: "",
            name: "CMS Company",
            component: () =>
              import(
                /* webpackChunkName: "company" */ "@/views/app/company-details/Index.vue"
              ),
          },
          {
            path: "",
            name: "Company Detail",
            component: () =>
              import(
                /* webpackChunkName: "company-detail" */ "@/views/app/company-details/Show.vue"
              ),
          },
          {
            path: "edit",
            name: "Edit Company Detail",
            component: () =>
              import(
                /* webpackChunkName: "company-detail" */ "@/views/app/company-details/Edit.vue"
              ),
          },
          {
            path: "content/edit/:contentId",
            name: "Edit Content",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "edit-editable-content" */ "@/views/app/company-details/editable-contents/Edit.vue"
              ),
          },
        ],
      },
      {
        path: "change-password",
        name: "Change Password",
        component: () =>
          import(
            /* webpackChunkName: "change-password" */ "@/views/app/ChangePassword.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: GuestMiddleware,
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    beforeEnter: GuestMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    beforeEnter: GuestMiddleware,
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/ResetPassword.vue"
      ),
  },
  {
    path: "/:catchAll(.*)*",
    name: "Not Found",
    props: true,
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue"),
  },
  {
    path: "/network-error",
    name: "Network Error",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/NetworkError.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        offset: { x: 0, y: 100 },
      };
    }
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

// Dynamic document title
router.beforeEach((to, from, next) => {
  let title = `${process.env.VUE_APP_NAME} | ${
    to.params.title ? to.params.title : to.name
  }`;
  document.title = title;
  next();
});

export default router;
