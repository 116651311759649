const labels = {
  COMPANY_NAME: "Allied Power Engineering",
  COMPANY_PHONE: "+1234567890",
  COMPANY_EMAIL: "support@alliedpowereng.com",
  COMPANY_ADDRESS: "Hetauda Industrial Estate",
  COMPANY_ADDRESS_2: "Hetauda, Bagamati, Nepal",
  COMPANY_POSTAL: "44100",

  DEV_NAME: "Softweb Developers",
  DEV_WEBSITE: "https://softwebdevelopers.com",

  // Menus
  MENU_HOME: "Home",
  MENU_ABOUT: "About Us",
  MENU_PRODUCTS: "Products",
  MENU_SERVICES: "Services",
  MENU_TEAMS: "Our Team",
  MENU_NEWS: "News",
  MENU_CONTACT: "Contact Us",
  MENU_PROJECTS: "Projects",
  MENU_GALLERY: "Gallery",
  MENU_CLIENTS: "Clients",

  //Labels
  LBL_LOGIN: "Login",
  LBL_LOGOUT: "Logout",
  LBL_FORGOT_PASSWORD: "Forgot Password",
  LBL_RESET_PASSWORD: "Reset Password",
  LBL_CHANGE_PASSWORD: "Change Password",
  LBL_CURRENT_PASSWORD: "Current Password",
  LBL_NEW_PASSWORD: "New Password",
  LBL_CONFIRM_PASSWORD: "Confirm Password",

  LBL_LOADING: "Loading",
  LBL_SENDING: "Sending",
  LBL_LOGGING_IN: "Logging In",
  LBL_LOGGING_OUT: "Logging Out",
  LBL_SUBSRCRIBING: "Subscribing",

  LBL_SUCCESS: "Success",
  LBL_ERROR: "Error",
  LBL_INFO: "Info",
  LBL_WARN: "Warning",
  LBL_OK: "Okay",
  LBL_YES: "Yes",
  LBL_CANCEL: "Cancel",
  LBL_NEW: "New",
  LBL_ADD: "Add",
  LBL_SAVE: "Save",
  LBL_UPDATE: "Update",
  LBL_EDIT: "Edit",
  LBL_NO_DATA: "No Data Found",
  LBL_FROM: "From",
  LBL_TO: "To",
  LBL_DATE: "Date",
  LBL_CONTENT: "Content",
  LBL_PHOTO: "Photo",
  LBL_DESIGNATION: "Designation",
  LBL_GRANT: "Grant",
  LBL_REVOKE: "Revoke",
  LBL_SALUTATIONS: "Salutations",
  LBL_ABOUT: "About",
  LBL_LOGO: "Logo",
  LBL_BANNERS: "Banners",
  LBL_SERVICES: "Services",

  LBL_LOGIN_ACCESS: "Login Access",
  LBL_SES_EXP: "Session Expired",
  LBL_ACTIVITY_LOGS: "Activity Logs",
  LBL_DESCRIPTION: "Description",
  LBL_PUBLISH_ALBUM: "Publish Album",
  LBL_PUBLISH_NEWS: "Publish News",
  LBL_PUBLISH_BANNER: "Publish Banner",
  LBL_TEAM_MEMBERS: "Team Members",
  LBL_CLIENTS: "Clients",
  LBL_COMPANIES: "Companies",
  LBL_QUICK_CONTACT: "Quick Contact",
  LBL_BATTERY_INSTALLED: "Battery Installed",

  LBL_SUBSCRIBE: "Subscribe",
  LBL_SUBSCRIBERS: "Subscribers",
  LBL_SUBSCRIBED_ON: "Subscribed on",
  LBL_INTERESTED_IN: "Interested In",

  LBL_ALL_RIGHTS: "All rights reserved",
  LBL_COPY_RIGHT: "© Copyright.",
  LBL_DESIGN_DEVELOP: "Designed and Developed by",
  LBL_SUSCRIBE_FOR_UPDATES: "Subscribe for latest updates",

  LBL_TITLE: "Title",
  LBL_SUBTITLE: "Subtitle",
  LBL_EDITOR: "Editor",

  LBL_URL: "Url",
  LBL_IMAGES: "Images",
  LBL_VIDEOS: "Videos",

  LBL_NAME: "Name",
  LBL_FIRST_NAME: "First Name",
  LBL_LAST_NAME: "Last Name",
  LBL_FULL_NAME: "Full Name",
  LBL_EMAIL: "Email Address",
  LBL_CONTACT_NO: "Contact Number",
  LBL_ADDRESS: "Address",
  LBL_ADDRESS_2: "Address 2",
  LBL_CITY: "City",
  LBL_STATE: "State",
  LBL_POSTAL: "Postal Code",
  LBL_COM_URL: "Company Url",
  LBL_COM_MOTTO: "Company Motto",
  LBL_HIGHEST_EDU: "Highest Education",

  LBL_SEND_MAIL: "Send Mail",
  LBL_CREATED: "Created on",
  LBL_PUBLISHED_ON: "Published on",
  LBL_STATUS: "Status",
  LBL_PUBLISHED: "Published",
  LBL_UNPUBLISHED: "Unpublished",
  LBL_REPLIED: "Replied",
  LBL_UNREPLIED: "Unreplied",
  LBL_TYPE_2_SEARCH: "Type to search",

  LBL_MESSAGE: "Message",
  LBL_REPLY: "Reply",
  LBL_SEND: "Send",
  LBL_FEEDBACK: "Feedback",
  LBL_OFFICE: "Office",
  LBL_DIRECT_CONTACT: "Direct Contact",
  LBL_WELCOME: "Welcome Back",
  LBL_RECENT_NEWS: "Recent News",
  LBL_FOLLOW_US: "Follow Us",

  LBL_LOAD_MORE: "Load More",
  LBL_SEE_MORE: "See More",
  LBL_LEARN_MORE: "Learn More",
  LBL_READ_MORE: "Read More",

  LBL_TESTIMONIALS: "Client's Reviews",
  LBL_INSTALLATION: "Installation",
  LBL_DES_ENG: "Design and Engineering",
  LBL_SALES_ANALYSIS: "Sales and Analysis",
  LBL_MAINTENANCE: "Maintenance",
  LBL_FINANCE: "Finance",
  LBL_VISION: "Our Vision",

  LBL_SYSTEM_ALBUM: "System Album",
  LBL_SYSTEM_TYPES: "System Types",
  LBL_SYSTEM_CAPACITY: "System Capaciy",

  LBL_PROJECTS: "Projects",
  LBL_PRODUCTS: "Products",
  LBL_COMPANY_NAME: "Company Name",
  LBL_COMPANY_DETAIL: "Company Detail",
  LBL_ESTD_DATE: "Established Date",

  LBL_SOCIAL_LINKS: "Social Media Links",
  LBL_FB: "Facebook",
  LBL_YT: "Youtube",
  LBL_LNKIN: "LinkedIn",
  LBL_TWI: "Twitter",
  LBL_INSTA: "Instagram",

  LBL_COMPANY_WORK_WITH: "Companies we have worked with",
  LBL_SOLAR_4_BUSINESS: "Solar For Business",
  LBL_SOLAR_4_HOME: "Solar For Home",
  LBL_BATTERY_AND_MORE: "Battery Storage & More",

  LBL_VALUABLE_CLIENTS: "Our Valuable Clients",

  //Placeholders
  PH_HOME_QUOTE: "Making energy eco-friendly for you, me and future",
  PH_ABOUT_QUOTE:
    "We are renewable energy company that  specialize exclusively in large-scale, commercial, and utility-scale solar power sales and installations.",
  PH_SERVICES_QUOTE: "We are best at what we do",
  PH_PROJECTS_QUOTE:
    "From 30kW to 2+MW, on schools, shopping centres and airports, no commercial project is off-limits for our team",
  PH_NEWS_QUOTE: "Get updated about what's happening in APE",
  PH_TEAM_QUOTE:
    " Great things in business are never done by one person. They're done by a team of people.",
  PH_TEAM_QUOTE_2:
    "The people behind our work are the most important part of what we do at APE.",

  PH_IN_TOUCH: "Get in touch",
  PH_IN_TOUCH_CAP: "Want to get in touch? We do love to hear from you.",
  PH_FIND_US: "How to Find Us",
  PH_START_CONVERS: "Let's start a conversation",

  PH_VISIT_US: "Give us a visit",
  PH_CUSTOMER_SUPPORT: "For supports or any questions",
  PH_MEET_TEAM: "Meet the Team",
  PH_PROJECT_DONE: "Projects we have done so far",
  PH_NEWS: "APE News",
  PH_NEWS_UPDATE: "News Updates for you",
  PH_LOGIN: "Login and lets get started!",
  PH_TESTIMONIALS: "See what our client have to say",
  PH_PRODUCTS:
    "We pride ourselves on offering a bespoke, end-to-end experience for our customers to ensure the upmost quality, accelerated delivery, and peace of mind.",
  PH_SERVICES:
    "We pride ourselves on offering a bespoke, end-to-end experience for our customers to ensure the upmost quality, accelerated delivery, and peace of mind.",
  PH_SOLAR_4_HOME:
    "A solar system for your home creates protection from fossil fuel’s uncertain future. Own your energy supply and secure your long-term freedom from rising electricity costs.",
  PH_SOLAR_4_BUSINESS:
    "Take advantage of solar energy to slash one of the biggest overheads businesses have to carry. Reduce your energy bills by as much as 80%. Get in touch and ask us how.",
  PH_BATTERY_AND_MORE:
    "Over 30 years providing better solutions for more efficient electricity generation and use, we’ve seen it all. Store, divert and save your energy for maximum savings.",
  PH_MAINTAINANCE:
    "Real time plant overviews & data acquisition to provide complete transparency on energy consumption, environmental impact and savings",
  PH_FINANCE:
    "Turnkey finance & power purchasing options tailored to your business",
  PH_COMPANY_WORK_WITH:
    "We have installed solar PV systems across multiple industries, including retail, education, government, aged care, hospitality and recreation.",

  PH_FEEDBACK: "Please enter your feedback ",
  PH_EMAIL: "Please enter email",
  PH_NAME: "Please enter name",
  PH_PASSWORD: "Please enter password",
  PH_NEW_PASSWORD: "Please enter new password",
  PH_FORGOT_PASSWORD:
    "Enter the email address you used when you joined and we'll send you instructions to reset your password.",
  PH_CONFIRM_PASSWORD: "Please confirm your password",
  PH_DESCRIPTION: "Please enter description",
  PH_TITLE: "Please enter title",
  PH_URL: "Please enter url",
  PH_REPLY: "Please enter message content",
  PH_EDITOR: "Please enter editor's name",
  PH_CONTENT: "Please enter content",
  PH_SELECT_DESIGNATION: "Select designation",
  PH_SYS_CAPACITY: "Please enter system capacity",

  PH_LOGIN_SUCECSS: "Logged in successfully",
  PH_LOGOUT_SUCECSS: "Logged out successfully",
  PH_PASS_CHANGE_SUCCESS: "Password successfully changed",
  PH_REDIRECTED: "You will be redirected to the login page.",
  PH_WARN_LOGOUT: "Are you sure you want to logout?",
  PH_RESET_LINK_SENT: "Reset Link sent successfully. Please check your email.",
  PH_FEEDBACK_SUBMITTED: "Feedback successfully submitted",
  PH_FEEDBACK_REPLIED: "Feedback successfully replied",
  PH_FEEDBACK_DELETED: "Feedback successfully deleted",
  PH_SUBSCRIBE_SUCCESS:
    "You have been successfully subscribed to our news letter",
  PH_SUBSCRIBER_DELETED: "Subscriber successfully deleted",
  PH_COM_DETAIL_UPDATED: "Company details successfully updated",
  PH_NEWS_CREATED: "News successfully created",
  PH_NEWS_UPDATED: "News successfully updated",
  PH_NEWS_DELETED: "News successfully deleted",
  PH_NEWS_IMAGE_DELETED: "News Image successfully deleted",
  PH_MEMBER_SUCCESS: "Team member successfully created",
  PH_MEMBER_UPDATED: "Team member successfully updated",
  PH_MEMBER_DELETED: "Team member successfully deleted",
  PH_LOGIN_STATUS_UPDATED: "Team member's login access status updated",
  PH_CLIENT_CREATED: "Client successfully created",
  PH_CLIENT_UPDATED: "Client successfully updated",
  PH_CLIENT_DELETED: "Client successfully deleted",

  PH_TESTIMONIAL_CREATED: "Testimonial successfully created",
  PH_TESTIMONIAL_UPDATED: "Testimonial successfully updated",
  PH_TESTIMONIAL_DELETED: "Testimonial successfully deleted",

  PH_ALBUMS_NF: "No Projects Albums found",
  PH_NEWS_NF: "No News found",
  PH_MEMBERS_NF: "No Members Found",
  PH_CLIENTS_NF: "No Clinets found",
  PH_TESTIMONIAL_NF: "No Testimonials found",

  //Error Messages
  ER_EMAIL: "Invalid Email Format",
  ER_PASSWORD_LENGTH: "Password length should be atleast 8",
  ER_PASSWORDS_MATCH: "Passwords donot match",
  ER_CPASSWORD_REQUIRED: "Confirm password is required",
  ER_INVALID_DATA: "The given data was invalid",
  ER_LENGTH_5: "Length should be atleast 5",
  ER_LENGTH_10: "Length should be atleast 10",
  ER_LENGTH_20: "Length should be atleast 20",

  //Confirmations
  CONF_DELETE: "Are you sure you want to delete",
  CONF_UPDATE: "Are you sure you want to update",
  CNCL_DELETE: "Deletion Cancelled",
  CNCL_ACCESS: "Access Cancelled",
};

export default labels;
