<template>
  <div class="side-nav" :class="navClass" @click="closeNav">
    <div class="side-nav__links">
      <div class="header" @click="closeNav">
        <router-link to="/">
          <img
            :src="require('@/assets/images/defaults/logo-full.png')"
            alt="logo"
            class="app-logo"
          />
        </router-link>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path
            d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
          />
        </svg>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SideNavigation",

    props: {
      value: { type: Boolean },
    },

    computed: {
      navClass() {
        return this.value ? "side-nav--open" : "";
      },
    },

    methods: {
      closeNav() {
        this.$emit("closeSidebar");
      },
    },
  };
</script>

<style lang="scss">
  .side-nav {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.24);
    color: black;
    display: none;
    transition: all 2s ease-in;

    &__links {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      width: 70%;
      background-color: white;
      display: flex;
      flex-direction: column;

      animation: slide-in 200ms ease-in-out;

      & > a {
        margin: 0.25rem 0;
        padding: 0.5rem 1rem;

        &:not(:last-child) {
          border-bottom: 1px solid lightgray;
        }
      }
    }

    &--open {
      display: block;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding: 0.5rem 1rem 0 1rem;

    svg {
      cursor: pointer;
      margin-top: 1rem;
    }
  }

  .app-logo {
    height: 60px;
    width: 70px;
    margin-top: -0.5rem;
  }
</style>
