import Vue from "vue";
import CoolLightBox from "vue-cool-lightbox";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { readableDate, kFormat } from "./common";

import AOS from "aos";
import "aos/dist/aos.css";

Vue.config.productionTip = false;

Vue.use(CoolLightBox);

Vue.filter("readableDate", readableDate);
Vue.filter("kFormat", kFormat);

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init({
      duration: 1000,
    });
  },
}).$mount("#app");
