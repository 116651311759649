<template>
  <el-container direction="vertical">
    <slot name="header"></slot>

    <el-container class="main-content">
      <slot name="sidebar"></slot>
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </el-container>

    <slot name="footer"></slot>
  </el-container>
</template>

<script>
  export default {
    name: "AppLayout",
  };
</script>

<style lang="scss">
  @import "@/assets/scss/main.scss";

  .main-content {
    margin-top: $header-height;
    min-height: 80vh;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 300ms ease-in-out;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateY(30px);
    opacity: 0;
  }
</style>
