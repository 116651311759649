<template>
  <div class="news-letter__wrapper">
    <!-- <div class="news-letter__wrapper">
      <div class="container">
        <div class="news-letter__contents">
          <div class="text">{{ labels.LBL_SUSCRIBE_FOR_UPDATES }}</div>
          <el-form
            ref="subscriberForm"
            :model="subscriber"
            :rules="rules"
            class="form"
          >
            <el-row type="flex">
              <el-form-item prop="email" style="min-width: 80%">
                <el-input
                  v-model="subscriber.email"
                  type="email"
                  :placeholder="labels.PH_EMAIL"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  @click="onSubmit('subscriberForm')"
                  style="margin-left: .5rem"
                >
                  {{ labels.LBL_SUBSCRIBE }}
                </el-button>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </div> -->

    <div class="container default-padding">
      <div class="news-letter__contents" data-aos="flip-up">
        <div class="news-letter__title">
          {{ labels.LBL_SUSCRIBE_FOR_UPDATES }}
        </div>
        <el-form
          ref="subscriberForm"
          :model="subscriber"
          :rules="rules"
          class="news-letter__form"
        >
          <el-row type="flex" style="width:100%">
            <el-form-item prop="email" style="width: 100%">
              <el-input
                v-model="subscriber.email"
                type="email"
                :placeholder="labels.PH_EMAIL"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                @click="onSubmit('subscriberForm')"
                style="margin-left: .5rem"
              >
                {{ labels.LBL_SUBSCRIBE }}
              </el-button>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import { labels, rules } from "@/common";

  export default {
    name: "Subscribe",

    data() {
      return {
        labels,
        rules,

        subscriber: {
          email: "",
        },
      };
    },

    methods: {
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store
              .dispatch("subscription/subscribe", this.subscriber)
              .then(() => {
                this.$refs[formName].resetFields();
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .news-letter {
    &__wrapper {
      background-color: $secondary-color;
      //padding: 1rem;
    }

    &__contents {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      flex: 1;
      font-size: 2.3rem;
      font-weight: 500;
      color: white;
    }

    &__form {
      flex: 1;
      margin-bottom: -1.5rem;
    }
  }

  @media only screen and (max-device-width: 768px) {
    .news-letter {
      &__contents {
        align-items: flex-start;
        flex-direction: column;
      }

      &__title {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
      }
    }
  }
</style>
