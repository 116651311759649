import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";
import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  testimonials: null,
  testimonialDetail: null,
};

const mutations = {
  SET_TESTIMONIALS: (state, testimonials) => {
    state.testimonials = testimonials;
  },

  SET_TESTIMONIAL_DETAIL: (state, detail) => {
    state.testimonialDetail = detail;
  },
};

const actions = {
  async getTestimonialDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`testimonials/${id}`);
      if (status === 200) {
        commit("SET_TESTIMONIAL_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        router.push({
          name: "Not Found",
          params: { resource: "testimonial" },
        });
      } else router.push({ name: "Network Error" });
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllTestimonials({ commit }, params = { page: 1 }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/testimonials", {
        params,
      });

      if (status === 200) {
        commit("SET_TESTIMONIALS", data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createTestimonial({ commit }, company) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        "/testimonials",
        createFormData(company)
      );
      if (status === 201) {
        router.push({ name: "CMS Testimonials" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_TESTIMONIAL_CREATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateTestimonial({ commit }, updatedTestimonial) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        `/testimonials/${updatedTestimonial.id}`,
        createFormData(updatedTestimonial)
      );
      if (status === 200) {
        router.push({ name: "CMS Testimonials" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_TESTIMONIAL_UPDATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteTestimonial({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/testimonials/${id}`);
      if (status === 200) {
        dispatch("getAllTestimonials");
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_TESTIMONIAL_DELETED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
