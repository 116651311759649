<template>
  <div>
    <app-layout>
      <template #header>
        <app-header></app-header>
      </template>
      <template #footer>
        <app-footer></app-footer>
      </template>
    </app-layout>
    <scroll-to-top />
  </div>
</template>

<script>
  import AppHeader from "@/components/layouts/AppHeader.vue";
  import AppLayout from "@/components/layouts/AppLayout.vue";
  import AppFooter from "@/components/layouts/AppFooter.vue";
  import ScrollToTop from "@/components/ScrollToTop";

  export default {
    name: "GuestLayout",

    components: { AppLayout, AppHeader, AppFooter, ScrollToTop },
  };
</script>
