import axios from "axios";
import { labels } from "@/common";
import { Loading, Notification } from "element-ui";
import router from "@/router";
import createFormData from "@/composables/useFormData";

const states = {
  orgDetail: null,
};

const mutations = {
  SET_ORGANIZATION_DETAIL: (state, { data }) => {
    state.orgDetail = data;
  },
};

const actions = {
  async getOrganizationDetail({ commit }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/organization");
      if (status === 200) {
        commit("SET_ORGANIZATION_DETAIL", data);
        return data.data;
      }
    } catch (err) {
      if (err.response && err.response.state === 404) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      } else router.push({ name: "Network Error" });
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateDetail(context, updatedDetail) {
    const loadingInstance = Loading.service({
      lock: true,
      text: labels.LBL_LOADING,
      background: "rgba(0, 0, 0, 0.7)",
    });
    try {
      const { status } = await axios.post(
        "/organization",
        createFormData(updatedDetail)
      );
      if (status === 200) {
        router.push("/admin/company-detail");
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_COM_DETAIL_UPDATED,
        });
      }
    } catch (err) {
      Notification({
        title: labels.LBL_ERROR,
        type: labels.LBL_ERROR.toLowerCase(),
        message: err.response.data.message,
      });
    } finally {
      loadingInstance.close();
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  states,
  mutations,
  actions,
  getters,
};
