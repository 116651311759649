<template>
  <el-aside class="aside" width="250px">
    <el-menu :default-active="$route.path">
      <div v-for="(menu, index) in menuItems" :key="index">
        <el-submenu v-if="menu.subMenus" :index="`${index}${menu.path}`">
          <template #title>
            <i :class="menu.icon"></i>
            <span> {{ menu.title }} </span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="item in menu.subMenus"
              :key="item.title"
              :index="`${menu.path}-${item.path}`"
              @click="goto(item.path)"
            >
              {{ item.title }}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item v-else :index="menu.path" @click="goto(menu.path)">
          <i :class="menu.icon"></i>
          <span> {{ menu.title }} </span>
        </el-menu-item>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
  export default {
    name: "AdminAside",

    data() {
      return {
        menuItems: [
          {
            title: "Dashboard",
            icon: "el-icon-odometer",
            path: "/admin",
          },
          {
            title: "Banners",
            icon: "el-icon-picture-outline",
            path: "/admin/banners",
          },
          {
            title: "Products",
            icon: "el-icon-setting",
            path: "/admin/products",
          },
          {
            title: "Services",
            icon: "el-icon-setting",
            path: "/admin/services",
          },
          {
            title: "Projects",
            icon: "el-icon-setting",
            path: "/admin/projects",
          },
          {
            title: "Gallery",
            icon: "el-icon-film",
            path: "/admin/gallery",
          },
          {
            title: "News",
            icon: "el-icon-postcard",
            path: "/admin/news",
          },
          // {
          //   title: "Team Members",
          //   icon: "el-icon-user",
          //   path: "/admin/team",
          // },
          {
            title: "Feedbacks",
            icon: "el-icon-chat-line-square",
            path: "/admin/feedbacks",
          },
          {
            title: "Subscribers",
            icon: "el-icon-message",
            path: "/admin/subscribers",
          },
          {
            title: "Clients",
            icon: "el-icon-pie-chart",
            path: "/admin/clients",
          },
          {
            title: "Testimonials",
            icon: "el-icon-pie-chart",
            path: "/admin/testimonials",
          },
          {
            title: "APE",
            icon: "el-icon-house",
            path: "/admin/company-detail",
          },
        ],
      };
    },

    methods: {
      goto(path) {
        this.$router.push(path);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .el-menu {
    border: none;
  }

  .aside {
    height: calc(100vh - #{$header-height});
    width: 100px;
    border-right: solid 1px #e6e6e6;
    overflow-y: scroll;
  }
</style>
