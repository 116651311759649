<template>
  <router-view />
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "App",

    computed: {
      ...mapState({
        token: (state) => state.auth.token,
        company: (state) => state.organization.orgDetail,
      }),
    },

    created() {
      this.checkToken();
      this.loadContent();
    },

    methods: {
      checkToken() {
        if (this.token) this.$store.dispatch("auth/checkToken");
      },

      loadContent() {
        this.$store.dispatch("editableContent/getAllEditableContents");
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/main.scss";

  .container {
    max-width: 1400px;
    margin: 0 auto;
  }

  .el-button {
    &--warning {
      background: $primary-color;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .btn-block {
    width: 100%;
  }

  .section-padding {
    padding: 3.75rem 0;
  }

  .primary-heading {
    position: relative;
    font-size: 2.15rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $tertiary-color;
    line-height: 3rem;
    display: inline-block;
    padding: 1rem 0;

    &::before {
      position: absolute;
      content: "";
      top: 2.5px;
      left: 0;
      height: 2px;
      width: 90%;
      background-color: $tertiary-color;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 5px;
      width: 20%;
      background-color: $primary-color;
    }

    &--sm {
      font-size: 1.75rem;
    }
  }

  .secondary-heading {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    color: $primary-color;
  }

  .caption {
    font-weight: 520;
    text-transform: uppercase;
    color: #222;
    margin-bottom: 1.5rem;
  }

  .paragraph {
    font-size: 1.15 rem;
    font-weight: 400;
    color: #555555;
    line-height: 1.7em;
  }

  .btn-text {
    display: inline-block;
    padding: 0.5rem;
    cursor: pointer;

    transition: all 200ms linear;

    &:hover {
      background: $background-color;
      color: $primary-color;
    }
  }

  .text-card {
    padding: 1rem;
    margin-top: 0.5rem;
    background: white;
    border-radius: 0.25rem;
  }

  .default-padding {
    padding: 1.5rem 8rem;

    @media only screen and (max-device-width: 768px) {
      padding: 1.5rem;
    }

    @media only screen and (min-device-width: 1500px) {
      padding: 1.5rem 12rem;
    }
  }

  .btn-lg {
    padding: 1rem 1.25rem;
    font-size: 1.125rem;
    text-transform: uppercase;
  }

  .disabled {
    color: gray;
    cursor: default;
  }

  .text-center {
    text-align: center;
  }

  .para {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 30px;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
</style>
