/* eslint-disable no-unused-vars */
"use strict";

import Vue from "vue";
import axios from "axios";
import Cookies from "js-cookie";
import router from "@/router";
import { labels } from "@/common";
import { MessageBox } from "element-ui";

axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
  "giga@accessToken"
)}`;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response.status === 401) {
      MessageBox.confirm(labels.PH_REDIRECTED, labels.LBL_SES_EXP, {
        confirmButtonText: labels.LBL_OK,
        type: labels.LBL_INFO.toLowerCase(),
      }).then(() => {
        Cookies.remove("giga@accessToken");
        router.push("/login");
      });
    }
    return Promise.reject(err);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      },
    },
    $axios: {
      get() {
        return axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
