<template>
  <div class="logo">
    <div class="logo__title">Allied</div>
    <div class="logo__subtitle">Power Engineering</div>
  </div>
</template>

<script>
  export default {
    name: "AppLogo",
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .logo {
    display: flex;
    flex-direction: column;

    text-transform: uppercase;

    &__title {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 40px;
      color: $primary-color;
    }

    &__subtitle {
      font-size: 0.6rem;
      letter-spacing: 2px;
    }
  }
</style>
