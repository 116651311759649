<template>
  <app-layout>
    <template #header>
      <app-header />
    </template>

    <template #sidebar>
      <admin-aside />
    </template>
  </app-layout>
</template>

<script>
  import AppLayout from "@/components/layouts/AppLayout";
  import AppHeader from "@/components/layouts/AppHeader";
  import AdminAside from "@/components/TheAdminAside.vue";

  export default {
    name: "AdminLayout",

    components: {
      AppLayout,
      AppHeader,
      AdminAside,
    },
  };
</script>

<style></style>
