import Cookies from "js-cookie";

export default (to, from, next) => {
  const token = Cookies.get("giga@accessToken");
  const auth = !!token;
  if (auth) {
    next({ name: "Dashboard" });
  } else {
    next();
  }
};
