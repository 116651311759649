import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import album from "./modules/album";
import expertise from "./modules/product";
import feedback from "./modules/feedback";
import subscription from "./modules/subscription";
import organization from "./modules/organization";
import news from "./modules/news";
import executive from "./modules/executive";
import clients from "./modules/clients";
import banner from "./modules/banner";
import testimonial from "./modules/testimonial";
import project from "./modules/project";
import service from "./modules/service";
import product from "./modules/product";
import editableContent from "./modules/editable-content";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isBusy: false,
  },

  mutations: {
    SET_BUSY: (state, busy) => {
      state.isBusy = busy;
    },
  },

  modules: {
    auth,
    album,
    banner,
    clients,
    executive,
    expertise,
    feedback,
    news,
    organization,
    subscription,
    testimonial,
    project,
    service,
    product,
    editableContent,
  },

  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["organization"],
    }),
  ],
});
