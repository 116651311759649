import axios from "axios";
import router from "@/router";
import { Notification } from "element-ui";
import { labels } from "@/common";

const state = {
  feedbacks: null,
  feedbackDetail: {},
};

const mutations = {
  SET_FEEDBACKS: (state, feedbacks) => {
    state.feedbacks = feedbacks;
  },

  SET_FEEDBACK_DETAIL: (state, detail) => {
    state.feedbackDetail = detail;
  },
};

const actions = {
  async getMessageDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`feedbacks/${id}`);
      if (status === 200) {
        commit("SET_FEEDBACK_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllFeedbacks({ commit }, params = { page: 1 }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("feedbacks", { params });
      if (status === 200) {
        commit("SET_FEEDBACKS", data);
        return data;
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createFeedback({ commit }, feedback) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post("feedbacks", feedback);
      if (status === 201) {
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_FEEDBACK_SUBMITTED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async replyFeedback({ commit }, reply) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.put(`feedbacks/${reply.id}`, reply);
      if (status === 200) {
        router.push("/admin/feedbacks");
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_FEEDBACK_REPLIED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteFeedback({ commit, dispatch }, { id, reply }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`feedbacks/${id}`);
      if (status === 200) {
        dispatch("getAllFeedbacks", { page: 1, reply });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_FEEDBACK_DELETED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
