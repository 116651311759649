import Cookies from "js-cookie";
import router from "@/router/index";
import { Notification, MessageBox, Loading } from "element-ui";
import { labels } from "@/common";
import axios from "axios";

const state = {
  token: Cookies.get("giga@accessToken") || null,
  loggedInUser: null,
};

const mutations = {
  SET_USER: (state, user) => {
    state.loggedInUser = user;
  },

  SET_TOKEN: (state, token) => {
    state.token = token;
  },
};

const actions = {
  async login({ commit }, credentials) {
    const loadingInstance = Loading.service({
      lock: true,
      text: labels.LBL_LOGGING_IN,
      background: "rgba(0, 0, 0, 0.7)",
    });
    try {
      const {
        status,
        data: { user, token },
      } = await axios.post("/login", credentials);

      if (status === 200) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        Cookies.set("giga@accessToken", token, { expires: 1 });

        commit("SET_USER", user);
        commit("SET_TOKEN", token);
        router.push({ name: "Dashboard" });

        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_LOGIN_SUCECSS,
        });
      }
    } catch (err) {
      if (err.response && (err.response.status == 404 || err.response.status == 422)) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      } else router.push({ name: "Network Error" });
    } finally {
      loadingInstance.close();
    }
  },

  async logout({ commit }) {
    const loadingInstance = Loading.service({
      lock: true,
      text: labels.LBL_LOGGING_OUT,
      background: "rgba(0, 0, 0, 0.7)",
    });
    try {
      const { status } = await axios("/logout");
      if (status === 200) {
        Cookies.remove("giga@accessToken", { expires: 1 });
        delete axios.defaults.headers.common.Authorization;

        commit("SET_USER", null);
        commit("SET_TOKEN", null);

        router.push("/login");

        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_LOGOUT_SUCECSS,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      loadingInstance.close();
    }
  },

  async checkToken({ commit }) {
    const loadingInstance = Loading.service({ fullscreen: true });
    try {
      const { status, data } = await axios.get("/user");
      if (status === 200) {
        commit("SET_USER", data.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      loadingInstance.close();
    }
  },

  async forgotPassword(_, forgotPassword) {
    const loadingInstance = Loading.service({
      lock: true,
      text: labels.LBL_LOADING,
      background: "rgba(0, 0, 0, 0.7)",
    });
    try {
      const res = await axios.post("/forgot-password", forgotPassword);
      if (res.status === 200) {
        MessageBox.alert(labels.PH_RESET_LINK_SENT, labels.LBL_RESET_PASSWORD, {
          confirmButtonText: labels.LBL_OK,
          type: labels.LBL_INFO.toLowerCase(),
        });
      }
    } catch (err) {
      if (err.response && err.response.status == 422) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: labels.INVALID_EMAIL,
        });
      }
    } finally {
      loadingInstance.close();
    }
  },

  async resetPassword(context, newCredentials) {
    const loadingInstance = Loading.service({
      lock: true,
      text: labels.LBL_LOADING,
      background: "rgba(0, 0, 0, 0.7)",
    });
    try {
      const { status } = await axios.post("/reset-password", newCredentials);

      if (status === 200) {
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.RESET_PASSWORD_SUCCESS,
        });
        router.push("/login");
      }
    } catch (err) {
      if (err.response && err.response.status == 422) {
        const tokenError = err.response.data.errors.token[0];

        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: tokenError || labels.ER_INVALID_DATA,
        });
      }
    } finally {
      loadingInstance.close();
    }
  },

  async changePassword(context, newCredentials) {
    const loadingInstance = Loading.service({
      lock: true,
      text: labels.LBL_LOADING,
      background: "rgba(0, 0, 0, 0.7)",
    });
    try {
      const {
        status,
        data: { token },
      } = await axios.put("/profile/change-password", newCredentials);

      if (status === 200) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        Cookies.set("giga@accessToken", token);
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_PASS_CHANGE_SUCCESS,
        });
        router.push("/admin");
      }
    } catch (err) {
      if (err.response && err.response.status == 422) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      loadingInstance.close();
    }
  },
};

const getters = {
  loggedIn: (state) => !!state.loggedInUser,

  isAdmin: (state) => state.loggedInUser && state.loggedInUser.id === 1,

  // dashboardCounts: (state) =>
  //   state.loggedInUser !== null && state.loggedInUser.counts,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
